import type { DateTimeFormats } from '@intlify/core';
const dateTime: DateTimeFormats = {
	en: {
		short: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		},
	},
	nl: {
		short: {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		},
		long: {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		},
	},
};

export default dateTime;
